import * as React from "react";
import { useState } from "react";
import CustomInput from "../../../../components/CustomInput";
import CustomButton from "../../../../components/CustomButton";
import { useMutation } from "react-query";
import { changePassword } from "../../../../apis/Auth";
import queryParamConstant from "../../../../utils/queryParamConstant";
import { toast } from "react-toastify";

const PasswordChange = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { mutate: updateChangePassword } = useMutation(
    queryParamConstant.CHANGE_PWD_URL,
    () =>
      changePassword({
        current_password: currentPassword,
        password: newPassword,
      }),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        toast.error(data?.message);
      },
    }
  );
  const handleSubmit = () => {
    if (
      newPassword !== "" &&
      confirmPassword !== "" &&
      currentPassword !== "" &&
      newPassword === confirmPassword
    ) {
      updateChangePassword();
    } else {
      if (newPassword !== confirmPassword) {
        toast.error("Please enter confirm password same as new password!");
      } else {
        toast.error("All field is required!");
      }
    }
  };
  return (
    <div className="flex flex-col">
      <div className="px-5 self-start text-[16px] text-blue leading-[20px] font-bold max-md:max-w-full">
        Password Change
      </div>
      <div className="mt-5 w-full border border-gray-light border-solid stroke-[1px] stroke-gray-200 max-md:max-w-full" />
      <div className="flex flex-col mt-5 max-w-full leading-[143%] w-[50%] max-md:px-5">
        <CustomInput
          type="password"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          title="Current password"
          placeholder="Enter here"
        />
      </div>
      <div className="flex gap-5 justify-center mt-8 leading-[143%] max-md:flex-wrap">
        <CustomInput
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          title="New password"
          subTitle="Your password must be at least 6 characters long."
          placeholder="Enter here"
        />
        <CustomInput
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          title="Confirm password"
          subTitle="Your password must be at least 6 characters long."
          placeholder="Enter here"
        />
      </div>

      <div className="justify-center self-end mr-10 max-md:mr-2.5">
        <CustomButton
          title="Save"
          className="px-5 py-1.5 mt-5"
          onClick={() => handleSubmit()}
        />
      </div>
    </div>
  );
};
export default PasswordChange;
