import { useRef, useState } from "react";
import { useMutation } from "react-query";
import { updateContactCardLinks } from "../../../../apis/Settings";
import CustomButton from "../../../../components/CustomButton";
import CustomInput from "../../../../components/CustomInput";
import CustomLightButton from "../../../../components/CustomLightButton/CustomLightButton";
import queryParamConstant from "../../../../utils/queryParamConstant";

const ContactCard = ({ data }) => {
  const upload = useRef();
  const backend_url = process.env.REACT_APP_API_BACKEND_URL;
  const [contactCardSiteInfor, setContactCardSiteInfor] = useState(
    data?.contact_card_site_info
  );
  const [fileData, setFileData] = useState();
  const [isAddSiteUrl, setAddSiteUrl] = useState(
    data?.is_add_site_url === 0 ? false : true
  );
  const [image, setImage] = useState(data?.contact_card_image);

  const [isAddSupportEmail, setAddSupportEmail] = useState(
    data?.is_add_support_email === 0 ? false : true
  );
  const { mutate: updateContactCard } = useMutation(
    queryParamConstant.UPDATE_CONTACT_INFO_URL,
    (formData) => updateContactCardLinks(formData),
    { refetchOnWindowFocus: false }
  );
  const onChangeFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event?.target?.files?.[0];
    if (file) {
      setImage(URL.createObjectURL(file));
    }
    setFileData(file);
  };
  const handleSubmitData = () => {
    const formData = new FormData();
    if (fileData) {
      formData.append("contact_card_image", image !== null ? fileData : "");
      formData.append("contact_card_site_info", contactCardSiteInfor);
      formData.append("contact_card_phone", data?.contact_card_phone);
      formData.append("is_add_support_email", isAddSupportEmail ? 1 : 0);
      formData.append("is_add_site_url", isAddSiteUrl ? 1 : 0);
      formData.append("contact_card_site_url", "");
      updateContactCard(formData);
    } else {
      const body = {
        contact_card_image: null,
        contact_card_site_info: contactCardSiteInfor,
        contact_card_phone: data?.contact_card_phone,
        is_add_support_email: isAddSupportEmail ? 1 : 0,
        is_add_site_url: isAddSiteUrl ? 1 : 0,
        contact_card_site_url: "",
      };
      updateContactCard(body);
    }
  };
  return (
    <div className="flex flex-col bg-white">
      <div className="flex gap-5 justify-between px-5 w-full max-md:flex-wrap max-md:max-w-full">
        <div className=" text-[16px] text-blue leading-[20px] font-bold max-md:max-w-full">
          Contact card
        </div>
        <div className="text-[14px] leading-[22px] text-zinc-600">
          Only in US & Canada
        </div>
      </div>
      <div className="mt-5 w-full border border-gray-light border-solid stroke-[1px] stroke-gray-200 max-md:max-w-full" />
      <div className="flex p-10 ">
        <div className="w-[60%] flex flex-col">
          <div className=" text-[14px] font-bold leading-[22px] text-blue  max-md:max-w-full">
            Send your company's contact information and let subscribers save
            your number as a contact in their phones.
          </div>
          <div className="flex flex-col items-start mt-8 w-full text-[14px] max-md:px-5 max-md:max-w-full">
            <div className="font-bold leading-[22px] text-blue max-md:max-w-full">
              Image (optional)
            </div>
            <div className="mt-1 text-[14px] leading-[22px] text-zinc-600 w-[481px] max-md:max-w-full mb-5">
              Supported file format is JPEG and the max file size is 25MB. The
              recommended image dimension is 400x400px, with no transparent
              background or animation.
            </div>
            <input
              id="myInput"
              type="file"
              ref={upload}
              style={{ display: "none" }}
              onChange={(e) => onChangeFile(e)}
            />
            {image && (
              <div className="flex gap-4 mb-4">
                <img src={image} alt="fileData" className="w-[100px]" />
                <button
                  onClick={() => setImage(null)}
                  className="text-red-500 font-bold"
                >
                  Remove
                </button>
              </div>
            )}
            <CustomLightButton
              title="Upload"
              isEnd={false}
              onClick={() => upload.current.click()}
            />
          </div>
          <div className="flex flex-col mt-8  max-w-full text-[14px]">
            <div className="font-bold leading-[22px] text-blue max-md:max-w-full">
              Site name
            </div>
            <div className="mt-1  text-[14px] leading-[22px] text-zinc-600 max-md:max-w-full">
              Teami Blends
              <span className="font-medium text-blue-500 pl-3">
                Edit in General Settings
              </span>
            </div>
            <div className="mt-1 leading-[22px] text-blue max-md:max-w-full">
              Site info (optional)
            </div>
            <div className="w-[50%]">
              <CustomInput
                placeholder="|"
                isPaddingZero
                onChange={(e) => setContactCardSiteInfor(e.target.value)}
                value={contactCardSiteInfor}
              />
            </div>
            <div className="mt-8 font-bold leading-[22px] text-blue max-md:max-w-full">
              Phone number
            </div>
            <div className="mt-1 leading-[22px] text-zinc-600 max-md:max-w-full">
              {data?.company_info_phone}
            </div>
            <div className="flex gap-5 pr-20 mt-4 max-md:flex-wrap max-md:pr-5">
              <div className="font-bold text-[14px] leading-[22px] text-blue">
                Support email address (optional)
              </div>
              <div className="flex gap-2.5 whitespace-nowrap leading-[22px] text-zinc-600">
                <input
                  type="checkbox"
                  value={isAddSupportEmail}
                  onChange={() => setAddSupportEmail(!isAddSupportEmail)}
                  className="shrink-0 self-start w-5 h-5 bg-white rounded-sm border border-solid border-gray-light"
                />
                <div>Add</div>
              </div>
            </div>
            <div className="mt-1 text-[14px] leading-[22px] text-zinc-600 max-md:max-w-full">
              No support email added yet.
              <span className="font-medium text-blue-500 pl-3">
                Edit in General Settings
              </span>
            </div>
            <div className="flex gap-5 pr-20 mt-4 max-md:flex-wrap max-md:pr-5 text-[14px]">
              <div className="font-bold leading-[22px] text-blue">
                Site URL (optional)
              </div>
              <div className="flex gap-2.5 whitespace-nowrap leading-[22px] text-zinc-600">
                <input
                  type="checkbox"
                  value={isAddSiteUrl}
                  onChange={() => setAddSiteUrl(!isAddSiteUrl)}
                  className="shrink-0 self-start w-5 h-5 bg-white rounded-sm border border-solid border-gray-light"
                />
                <div>Add</div>
              </div>
            </div>
            <div className="mt-1 text-[14px] leading-[22px] text-zinc-600 max-md:max-w-full">
              https://www.teamiblends.com
            </div>
            <div className="flex mt-5">
              <CustomButton
                title="Save"
                isEnd={false}
                className={"px-8"}
                onClick={() => handleSubmitData()}
              />
            </div>
          </div>
        </div>
        <div className="w-[40%] flex flex-col px-5">
          <div className="flex flex-col  p-5 bg-white rounded-xl shadow-2xl">
            <div className="items-start px-5 pb-5 text-sm font-bold leading-5 whitespace-nowrap text-blue">
              Preview
            </div>
            <div className="flex  px-2.5 py-2.5 mt-1 rounded border border-gray-light border-solid text-neutral-500 max-md:flex-wrap justify-between items-center bg-[#FAFAFA]">
              <div className="flex gap-2.5 justify-center items-center">
                <div>{data?.site_name}</div>{" "}
                <div className="img-avatar-small p-1">
                  {image !== null && image !== "null" ? (
                    <img
                      src={backend_url + image}
                      alt="fileData"
                      className="w-[20px]"
                    />
                  ) : (
                    data?.site_name[0]
                  )}
                </div>
              </div>
              {/* <DownArrowIcon /> */}
            </div>

            <div className="flex flex-col items-center px-5 py-10 mt-5 rounded-xl bg-[#FAFAFA]">
              <div className="img-avatar p-3">
                {image !== null && image !== "null" ? (
                  <img
                    src={backend_url + image}
                    alt="fileData"
                    className="w-[80px]"
                  />
                ) : (
                  data?.site_name[0]
                )}
              </div>
              <div className="mt-6 text-xl font-medium leading-7 text-blue">
                {data?.site_name}
              </div>
              <div className="flex flex-col self-stretch py-2.5 pl-3.5 mt-20 text-sm bg-white rounded border border-gray-200 border-solid">
                <div className="text-[14px] leading-[22px] font-bold text-blue">
                  Phone number
                </div>
                <div className="mt-1 text-[14px] leading-[22px] text-zinc-600">
                  {data?.company_info_phone}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactCard;
