// ? General Config

export const RES_PER_PAGE = 10;

export const rangeFilter = {
  yesterday: "Yesterday",
  today: "Today",
  seven_days: "Last 7 Days",
  thirty_days: "Last 30 Days",
  this_month: "This Month",
  all: "All Time",
  custom: "Custom Range",
};

export const subscribersTypeFilter = {
  new_subscribers: "New Subscriptions",
  cancelled_subscribers: "Cancelled subscriptions",
  subscribers_count: "Subscribers count",
};

export const subscriberChartRange = {
  daily: "Daily",
  weekly: "Weekly",
  monthly: "Monthly",
  yearly: "Yearly",
};

export const optinFilterTitle = {
  popup_type: "Type",
  status: "Status",
  device: "Device",
};

export const optinFilter = {
  popup_type: {
    popup: "Popup",
    keyword: "Keyword",
    landing_page: "Landing page",
    customer_chat: "Customer chat",
    widget: "Widget",
  },
  status: {
    active: "Active",
    draft: "Draft",
    inactive: "Inctive",
  },
  device: {
    mobile: "Mobile",
    desktop: "Desktop",
  },
};

export const autoFlowsLink = {
  welcome: "/automated-flows/sms-welcome",
  custom_triggers: "/automated-flows/sms-custom-triggers",
  browser_sms_abandonment: "/automated-flows/sms-browse-abandonment",
  abandoned_cart: "/automated-flows/sms-abandoned-cart",
  order_receipt: "/automated-flows/sms-receipt",
  sms_abandonment: "/automated-flows/sms-browse-abandonment",
  sms_order__receipt: "/automated-flows/sms-receipt",
  fulfillment_notification: "/automated-flows/sms-fulfillment-notification",
  help: "/automated-flows/sms-help",
};

export const autoFlowIdConfig = {
  welcome: 1,
  custom_triggers: 2,
  browse_abandonment: 3,
  abandoned_cart: 4,
  receipt: 5,
  fulfillment_notification: 6,
  help: 7,
};

export const autoFlowsDetailFilter = {
  all: "All",
  active: "Active",
  inactive: "Inactive",
  //  inuse: 'In use',
  //  not_inuse: 'Not in use',
};

export const campaignFlowsFilter = {
  all: "All",
  draft: "Draft",
  scheduled: "Scheduled",
  sending: "Sending",
  sent: "Sent",
};

export const subscriberPersonalDetails = {
  phoneNo: "Phone",
  country: "Country",
  region: "Region",
  city: "City",
  language: "Language",
  timezone: "Timezone",
  firstName: "First Name",
  lastName: "Last Name",
  zipCode: "Zip code",
  gender: "Gender",
  state: "State",
};

export const subscriberTooltipInfo = {
  language:
    "Language is based on the locale information the subscriber provided on Facebook or captured from the browser",
};

export const sendingNumberType = {
  toll_free: "Toll-free",
  short_code: "Shortcode",
};
