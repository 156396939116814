/* eslint-disable jsx-a11y/anchor-is-valid */
import Logo from "../../assets/images/logo.png";
import Vector from "../../assets/images/header-vector-right.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HeaderData from "../../utils/headerData";
import RightArrow from "../../assets/icons/RightArrow";
import { useQuery } from "react-query";
import queryParamConstant from "../../utils/queryParamConstant";
import { getAllSettings } from "../../apis/Settings";
const CustomHeader = ({ isOpenSidebar }) => {
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const { data } = useQuery(
    queryParamConstant.GET_ALL_SETTINGS_URL,
    () => getAllSettings({}),
    { refetchOnWindowFocus: false }
  );
  const backend_url = process.env.REACT_APP_API_BACKEND_URL;
  return (
    <header
      className={`main-header z-0 ${isOpenSidebar ? "open-sidebar" : ""} `}
    >
      <div className="flex gap-5 justify-between pl-8 text-base leading-6 text-white bg-blue-800 rounded-xl max-md:flex-wrap max-md:pl-5">
        <div className="flex gap-5 self-start mt-5">
          {pathname === "/" || pathname === "/dashboard" ? (
            <>
              <figure>
                <div className="img-avatar-header rounded-2xl">
                  {data?.settings?.[0]?.contact_card_image !== null &&
                  data?.settings?.[0]?.contact_card_image !== "null" ? (
                    <img
                      src={
                        backend_url + data?.settings?.[0]?.contact_card_image
                      }
                      alt="fileData"
                      className="w-[40px]"
                    />
                  ) : (
                    data?.settings?.[0]?.site_name[0]
                  )}
                </div>
              </figure>
              <div>
                <h6 className="text-white text-[16px] font-bold	leading-[24px]">
                  {data?.settings?.[0]?.site_name}
                </h6>
                <Link
                  href="https://www.teamiblends.com"
                  className="text-white text-[16px] font-semibold	leading-[24px]"
                >
                  {data?.settings?.[0]?.site_url}
                </Link>
              </div>
            </>
          ) : (
            <div className="flex flex-col justify-center text-white">
              <div className="flex gap-3.5 justify-between self-start items-center pr-5 pb-3">
                <div className="text-white text-[20px] font-bold leading-[24px] capitalize">
                  {state?.name
                    ? state?.name
                    : pathname?.split("/")[2]
                    ? pathname?.split("/")[2]?.replaceAll("-", " ")
                    : HeaderData[pathname.split("/")[1]]?.title}
                </div>
                <div className="w-[2px] bg-[#3374CC] h-[14px]"></div>
                {HeaderData[pathname.split("/")[1]]?.breadcrumbs.map(
                  (item, index) => (
                    <div className="flex gap-3.5" key={item?.title + index}>
                      <div className="flex gap-1 whitespace-nowrap">
                        <div
                          className="text-[16px] leading-[24px] font-normal cursor-pointer"
                          onClick={() => navigate("/")}
                        >
                          {item?.title}
                        </div>
                        {index !==
                          HeaderData[pathname.split("/")[1]]?.breadcrumbs
                            .length && (
                          <div className="flex-1 text-xs font-black tracking-normal text-center">
                            <RightArrow />
                          </div>
                        )}
                      </div>
                    </div>
                  )
                )}{" "}
                {pathname?.split("/")[2] && (
                  <div
                    className="text-[16px] leading-[24px] font-normal capitalize cursor-pointer"
                    onClick={() =>
                      navigate(
                        pathname.split("/")[1].includes("sequences")
                          ? "/campaign-flows"
                          : pathname.split("/")[1]
                      )
                    }
                  >
                    {!pathname?.split("/")[2]
                      ? pathname?.split("/")[2]?.replaceAll("-", " ")
                      : HeaderData[pathname.split("/")[1]]?.title || ""}
                  </div>
                )}
                {pathname?.split("/")[2] && <RightArrow />}
                <div className="text-[16px] leading-[24px] font-normal capitalize cursor-default">
                  {state?.name
                    ? state?.name
                    : pathname?.split("/")[2]
                    ? pathname?.split("/")[2]?.replaceAll("-", " ")
                    : HeaderData[pathname.split("/")[1]]?.title}
                </div>{" "}
              </div>
              <div className="text-white text-[16px] font-normal leading-[24px] ">
                {state?.description ||
                  HeaderData[pathname.split("/")[1]]?.subtitle}
              </div>
            </div>
          )}
        </div>
        <div className="shrink-0 max-w-full aspect-[0.9] fill-stone-300 fill-opacity-20">
          <img src={Vector} alt="vector" loading="lazy" />
        </div>
        <div className="main-header__responsive">
          <a href="#" className="hamburger-icon">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.5 10H17.5"
                stroke="#D0D1D2"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2.5 5H17.5"
                stroke="#D0D1D2"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2.5 15H17.5"
                stroke="#D0D1D2"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </a>
          <figure>
            <img src={Logo} alt="logo" loading="lazy" />
          </figure>
        </div>
      </div>
    </header>
  );
};

export default CustomHeader;
