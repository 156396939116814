const queryParamConstant = {
  GET_OPTIN_DATA_URL: "GET_OPTIN_DATA_URL",
  GET_AUTOMATED_FLOWS_LIST_URL: "GET_AUTOMATED_FLOWS_LIST_URL",
  GET_AUTOMATED_FLOWS_OVERVIEW_URL: "GET_AUTOMATED_FLOWS_OVERVIEW_URL",
  GET_CAMPAIGN_FLOWS_OVERVIEW_URL: "GET_CAMPAIGN_FLOWS_OVERVIEW_URL",
  GET_CAMPAIGN_FLOWS_LIST_URL: "GET_CAMPAIGN_FLOWS_LIST_URL",
  GET_SEGMENTS_LIST_URL: "GET_SEGMENTS_LIST_URL",
  GET_SEGMENT_DETAILS_URL: "GET_SEGMENT_DETAILS_URL",
  GET_ROLES_URL: "GET_ROLES_URL",
  GET_REVENUE_REPORT_URL: "GET_REVENUE_REPORT_URL",
  GET_SMS_USAGE_URL: "GET_SMS_USAGE_URL",
  GET_SEGMENT_SUBSCRIBERS_URL: "GET_SEGMENT_SUBSCRIBERS_URL",
  GET_SEGMENT_CAMPAIGNS_URL: "GET_SEGMENT_CAMPAIGNS_URL",
  GET_SEGMENT_DETAILS_INSIDE_URL: "GET_SEGMENT_DETAILS_INSIDE_URL",
  GET_OPTIN_DATA_URL_HOME: "GET_OPTIN_DATA_URL_HOME",
  GET_AUTOMATED_FLOWS_LIST_URL_HOME: "GET_AUTOMATED_FLOWS_LIST_URL_HOME",
  GET_CAMPAIGN_FLOWS_LIST_URL_HOME: "GET_CAMPAIGN_FLOWS_LIST_URL_HOME",
  GET_SUBSCRIBERS_CHART_DATA_URL_HOME: "GET_SUBSCRIBERS_CHART_DATA_URL_HOME",
  GET_SUBSCRIBERS_CHART_DATA_URL_HOME_LAST:
    "GET_SUBSCRIBERS_CHART_DATA_URL_HOME_LAST",
  GET_SUBSCRIBERS_LIST_URL: "GET_SUBSCRIBERS_LIST_URL",
  GET_SUBSCRIBERS_CHART_DATA_URL: "GET_SUBSCRIBERS_CHART_DATA_URL",
  GET_IMPORTED_FILES_LIST_URL: "GET_IMPORTED_FILES_LIST_URL",
  GET_SUBSCRIBERS_OVERVIEW_URL: "GET_SUBSCRIBERS_OVERVIEW_URL",
  GET_CHAT_MSGS_URL: "GET_CHAT_MSGS_URL",
  GET_SUBSCRIBER_INFO_URL: "GET_SUBSCRIBER_INFO_URL",
  GET_ALL_CHATS_URL: "GET_ALL_CHATS_URL",
  GET_SUBSCRIBER_LIST_AND_SEGMENTS_URL: "GET_SUBSCRIBER_LIST_AND_SEGMENTS_URL",
  GET_ALL_SETTINGS_URL: "GET_ALL_SETTINGS_URL",
  SENG_MSG_URL: "SENG_MSG_URL",
  GET_TERM_TYPES_URL_USER: "GET_TERM_TYPES_URL_USER",
  GET_TERM_TYPES_URL_ROLE: "GET_TERM_TYPES_URL_ROLE",
  GET_RIGHTS_URL: "GET_RIGHTS_URL",
  ADD_ROLE_RIGHTS_URL: "ADD_ROLE_RIGHTS_URL",
  GET_SUBSCRIBER_INFO_URL_DETAIL: "GET_SUBSCRIBER_INFO_URL_DETAIL",
  GET_SUBSCRIPTION_HISTORY_URL: "GET_SUBSCRIPTION_HISTORY_URL",
  LOGIN_URL: "LOGIN_URL",
  FORGOT_PWD_URL: "FORGOT_PWD_URL",
  RESET_PWD_URL: "RESET_PWD_URL",
  GET_TIMEZONE_OPTIONS_URL: "GET_TIMEZONE_OPTIONS_URL",
  UPDATE_SITE_SETTINGS_URL: "UPDATE_SITE_SETTINGS_URL ",
  UPDATE_COMPANY_INFO_URL: "UPDATE_COMPANY_INFO_URL",
  UPDATE_PRIVACY_LINKS_URL: "UPDATE_PRIVACY_LINKS_URL",
  UPDATE_CONTACT_INFO_URL: "UPDATE_CONTACT_INFO_URL",
  UPDATE_SENDING_SETTINGS_URL: "UPDATE_SENDING_SETTINGS_URL",
  CHANGE_PWD_URL: "CHANGE_PWD_URL",
};
export default queryParamConstant;
