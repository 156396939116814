const Pagination = ({
  perPage,
  page,
  setPerPage,
  setPage,
  previousPage,
  totalRows,
  nextPage,
}) => {
  const handlePerRowsChange = async (e, page) => {
    const numberOfPage = parseInt(e.target.value);
    setPerPage(numberOfPage);
  };

  return (
    <div className="flex gap-5 px-5 mt-8 w-full  max-md:flex-wrap max-md:max-w-full">
      <div className="flex-1 my-auto text-sm text-stone-500">
        Showing {page > 1 ? (page - 1) * perPage : 1} to{" "}
        {(page > 1 ? (page - 1) * perPage : 0) + perPage > totalRows
          ? totalRows
          : (page > 1 ? (page - 1) * perPage : 0) + perPage}{" "}
        of {totalRows} Entries
      </div>
      <div className="flex flex-col py-px pl-5 text-sm tracking-tight whitespace-nowrap text-zinc-700">
        <div className="flex gap-5 justify-between items-center self-start">
          <div className="self-stretch my-auto">Page</div>
          <div className="flex gap-4 justify-center items-center self-stretch text-center text-white">
            <button
              className="shrink-0 self-stretch my-auto aspect-[0.5] fill-blue-800 w-[3px]"
              onClick={() => {
                if (page !== 1) {
                  previousPage(page - 1);
                  setPage(page > 1 ? page - 1 : 1);
                }
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="4"
                height="6"
                viewBox="0 0 4 6"
                fill="none"
              >
                <path
                  d="M3.5 4.91289L3.5 1.08711C3.5 0.659934 2.99894 0.429479 2.6746 0.707482L0.4429 2.62037C0.210094 2.81992 0.210094 3.18008 0.4429 3.37963L2.6746 5.29252C2.99894 5.57052 3.5 5.34007 3.5 4.91289Z"
                  fill="#2B61AB"
                />
              </svg>
            </button>
            <div className="flex flex-col justify-center self-stretch">
              <div className="flex justify-center items-center text-[13px] font-medium leading-[15.25px] w-6 h-6 bg-blue-800 rounded-full shadow-sm">
                {page}
              </div>
            </div>
            <button
              className="shrink-0 self-stretch my-auto aspect-[0.5] fill-blue-800 w-[3px]"
              onClick={() => {
                const pageCount = totalRows / perPage;
                setPage(page < Math.ceil(pageCount) ? page + 1 : page);
                if (page < Math.ceil(pageCount)) {
                  nextPage();
                }
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="4"
                height="6"
                viewBox="0 0 4 6"
                fill="none"
              >
                <path
                  d="M0.5 1.08711L0.5 4.91289C0.5 5.34007 1.00106 5.57052 1.3254 5.29252L3.5571 3.37963C3.78991 3.18008 3.78991 2.81992 3.5571 2.62037L1.3254 0.707482C1.00106 0.429479 0.5 0.659934 0.5 1.08711Z"
                  fill="#2B61AB"
                />
              </svg>
            </button>
          </div>
          <div className="flex gap-2.5 self-stretch my-auto text-center">
            <select
              className="border-blue border-b-2 py-2 w-[50px] outline-none"
              name={"perPage"}
              onChange={(value) => handlePerRowsChange(value)}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
              <option value={50}>50</option>
              {/* <option value={100}>100</option> */}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Pagination;
