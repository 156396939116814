const CustomStepper = ({
  active = 0,
  stepperList = [
    { id: 1, title: "Site information" },
    { id: 2, title: "Contact information" },
    { id: 3, title: "Company information" },
    { id: 4, title: "Password Change" },
  ],
  onClick,
}) => {
  return (
    <div className="px-5 relative">
      <div
        className="mt-5 border border-gray-light border-solid stroke-[1px] stroke-gray-200 max-md:max-w-full w-[1px] absolute left-[2.7rem] -top-1  z-0"
        style={{
          height:
            stepperList.length <= 3
              ? stepperList.length <= 2
                ? "50px"
                : "100px"
              : "150px",
        }}
      />
      <div className="flex flex-col self-start mt-2.5">
        {stepperList.map((item, index) => (
          <button
            key={item?.id + index}
            className="flex gap-2.5 px-5 z-10 mb-8 cursor-pointer"
            onClick={() => onClick(index)}
          >
            <div
              className={`shrink-0 my-auto w-[8px] h-[8px] rounded-full  ${
                active === index ? "bg-blue-800" : "bg-zinc-500"
              }`}
            />
            <div
              className={`text-[16px] leading-[20px]  ${
                active === index
                  ? "text-blue-800 font-bold"
                  : "font-normal text-zinc-500"
              }`}
            >
              {item?.title}
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};
export default CustomStepper;
