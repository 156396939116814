import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getAutomatedFlowsList } from "../../apis/AutomatedFlows";
import CustomLoader from "../../components/CustomLoader";
import RenderTable from "../../components/RenderTable";
import { autoFlowsLink } from "../../global/config";
import queryParamConstant from "../../utils/queryParamConstant";
import columns from "./DatatableConfig";
import { useState } from "react";

const AutomatedFlowsPage = () => {
  const navigate = useNavigate();
  const [selectedRange, setSelectedRange] = useState("all");
  const [page, setPage] = useState(1);
  const [allDataList, setAllDataList] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const { data, error, isLoading, isFetching, refetch } = useQuery(
    queryParamConstant.GET_AUTOMATED_FLOWS_LIST_URL,
    () =>
      getAutomatedFlowsList({
        automated_flow_id: 1,
        filter: {
          search_text: "",
          popup_type: "",
          status: "",
          device: "",
          data_range: selectedRange,
          start_date: "",
          end_date: "",
        },
        page: 1,
        limit: 10,
        is_with_paginate: 0,
      }),
    {
      refetchOnWindowFocus: false,
      onSuccess: (list) => {
        setFilterData(list?.list);
        setAllDataList(
          page === 1 ? [...list?.list] : [...allDataList, ...list?.list]
        );
      },
    }
  );

  if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <div className="relative mb-10 bg-white rounded-[10px] border-2 border-[#DADADA] py-6">
      {isLoading ? (
        <div className="h-[500px]">
          <CustomLoader />
        </div>
      ) : (
        <RenderTable
          isLoading={isFetching}
          refetch={refetch}
          totalRows={data?.total}
          page={page}
          perPage={perPage}
          setPerPage={setPerPage}
          setPage={setPage}
          allDataList={allDataList}
          tableData={filterData}
          columns={columns}
          isSearch={false}
          isFilter={false}
          isLeftSideFilter={false}
          filterSelection={selectedRange}
          onFilterChange={(item) => {
            setPage(1);
            setSelectedRange(item);
            setTimeout(() => refetch(), 10);
          }}
          onRowClicked={(row) => {
            navigate(
              autoFlowsLink[
                row?.category_name
                  ?.toLowerCase()
                  ?.replaceAll(" ", "_")
                  ?.replace("&", "")
              ],
              {
                state: {
                  id: row?.automated_flow_id,
                  description: row?.description,
                },
              }
            );
          }}
        />
      )}
    </div>
  );
};
export default AutomatedFlowsPage;
