const Line = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M1.60026 8.80007C1.60026 8.35824 1.95843 8.00007 2.40026 8.00007H4.00026C4.44209 8.00007 4.80026 8.35824 4.80026 8.80007V12.8001C4.80026 13.2419 4.44209 13.6001 4.00026 13.6001H2.40026C1.95843 13.6001 1.60026 13.2419 1.60026 12.8001V8.80007Z"
      fill="white"
    />
    <path
      d="M6.40026 5.60007C6.40026 5.15824 6.75843 4.80007 7.20026 4.80007H8.80026C9.24209 4.80007 9.60026 5.15824 9.60026 5.60007V12.8001C9.60026 13.2419 9.24209 13.6001 8.80026 13.6001H7.20026C6.75843 13.6001 6.40026 13.2419 6.40026 12.8001V5.60007Z"
      fill="white"
    />
    <path
      d="M11.2003 3.20007C11.2003 2.75824 11.5584 2.40007 12.0003 2.40007H13.6003C14.0421 2.40007 14.4003 2.75824 14.4003 3.20007V12.8001C14.4003 13.2419 14.0421 13.6001 13.6003 13.6001H12.0003C11.5584 13.6001 11.2003 13.2419 11.2003 12.8001V3.20007Z"
      fill="white"
    />
  </svg>
);
export default Line;
