import { useState } from "react";
import CustomTabs from "../../components/CustomTabs";
import GeneralTab from "./GeneralTab";
import SMSTab from "./SMSTab/SMSTab";
import { useQuery } from "react-query";
import queryParamConstant from "../../utils/queryParamConstant";
import { getAllSettings } from "../../apis/Settings";
import CustomLoader from "../../components/CustomLoader";
import { useNavigate } from "react-router-dom";

const SettingsPage = ({ isSMS = false }) => {
  const navigate = useNavigate();
  const [active, setActive] = useState(isSMS ? 1 : 0);
  const { data, error, isLoading } = useQuery(
    queryParamConstant.GET_ALL_SETTINGS_URL,
    () => getAllSettings({}),
    { refetchOnWindowFocus: false }
  );

  if (isLoading) return <CustomLoader />;

  if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <div className="relative mb-10 bg-white rounded-[10px] border-2 border-[#DADADA] py-6">
      <div className="flex z-10 flex-col ml-10 max-w-full text-base leading-6 whitespace-nowrap w-[129px] max-md:ml-2.5">
        <CustomTabs
          tabsList={[
            { id: 1, title: "General" },
            { id: 2, title: "SMS" },
          ]}
          active={active}
          onClick={(index) => {
            setActive(index);
            navigate(index === 1 ? "/settings/sms" : "/settings/general");
          }}
        />
      </div>
      {active === 0 ? <GeneralTab data={data} /> : <SMSTab data={data} />}
    </div>
  );
};
export default SettingsPage;
