import { toast } from "react-toastify";
import CopyIcon from "../../../../assets/icons/CopyIcon";
import InformIcon from "../../../../assets/icons/InfomIcon";
import CustomButton from "../../../../components/CustomButton";
import CustomInput from "../../../../components/CustomInput";
import CustomLightButton from "../../../../components/CustomLightButton/CustomLightButton";
import CustomSelect from "../../../../components/CustomSelect";
import copy from "copy-to-clipboard";
import { useState } from "react";
import { UpdateSiteSetting, timezoneOptions } from "../../../../apis/Settings";
import queryParamConstant from "../../../../utils/queryParamConstant";
import { useMutation, useQuery } from "react-query";
const SiteInformation = ({ data }) => {
  const [siteName, setSiteName] = useState(data?.site_name);
  const backend_url = process.env.REACT_APP_API_BACKEND_URL;
  const [siteTimeZone, setTimeZone] = useState(data?.site_timezone);
  const [supportEmail, setSupportEmail] = useState(
    data?.site_support_email_address
  );
  const { data: timeZoneList } = useQuery(
    queryParamConstant.GET_TIMEZONE_OPTIONS_URL,
    () => timezoneOptions({}),
    { refetchOnWindowFocus: false }
  );
  const { mutate: updateSiteData } = useMutation(
    queryParamConstant.UPDATE_SITE_SETTINGS_URL,
    () =>
      UpdateSiteSetting({
        site_url: data?.site_url,
        site_id: data?.site_id,
        site_currency: data?.site_currency,
        site_name: siteName,
        site_timezone: siteTimeZone,
        site_support_email_address: supportEmail,
      }),
    { refetchOnWindowFocus: false }
  );

  return (
    <div>
      <div className="mx-5 text-base font-bold text-blue max-md:mr-2.5 max-md:max-w-full">
        Site information
      </div>
      <div className="mt-5 w-full border border-gray-light border-solid stroke-[1px] stroke-gray-200 max-md:max-w-full" />
      <div className="mt-4 px-10">
        <div className="flex items-center gap-5 text-sm max-md:flex-wrap">
          <div className="flex items-center">
            <div className="img-avatar p-3">
              {data?.contact_card_site_info !== null &&
              data?.contact_card_site_info !== "null" ? (
                <img
                  src={backend_url + data?.contact_card_site_info}
                  alt="fileData"
                  className="w-[80px]"
                />
              ) : (
                data?.site_name[0]
              )}
            </div>
            <div className="flex flex-col flex-1 px-5 pb-3.5 mt-3">
              <div className="flex gap-1.5 font-medium text-[14px] leading-[20px] text-blue">
                Site URL{" "}
                <span data-tooltip-id="site-url">
                  <InformIcon />
                </span>
              </div>
              <div className="flex items-center gap-2.5 px-0.5 mt-2 whitespace-nowrap ">
                <div className="text-[14px] leading-[22px] font-medium text-zinc-600">
                  {data?.site_url}
                </div>
                <span
                  onClick={() => {
                    toast.success("Copy to clipboard!");
                    copy(data?.site_url, {
                      debug: true,
                      message: "Press #{key} to copy",
                    });
                  }}
                >
                  <span data-tooltip-id="copy">
                    <CopyIcon />
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-col flex-1 px-5 pb-3.5">
            <div className="font-medium leading-[143%] text-blue">Site ID</div>
            <div className="flex items-center gap-2.5 px-0.5 mt-1 whitespace-nowrap leading-[157%] text-zinc-600">
              <div className="text-[14px] leading-[22px] font-medium text-zinc-600">
                {data?.site_id}
              </div>
              <span
                onClick={() => {
                  toast.success("Copy to clipboard!");
                  copy(data?.site_id, {
                    debug: true,
                    message: "Press #{key} to copy",
                  });
                }}
              >
                <span data-tooltip-id="copy">
                  <CopyIcon />
                </span>
              </span>
            </div>
          </div>
          <div className="hidden flex-col flex-1 px-5 pb-3.5 whitespace-nowrap">
            <div className="font-medium text-[14px] leading-[20px] text-blue">
              Currency
            </div>
            <div className="text-[14px] leading-[22px] font-medium text-zinc-600">
              {data?.site_currency}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 w-full border border-gray-light border-solid stroke-[1px] stroke-gray-200 max-md:max-w-full" />
      <div className="flex flex-col self-stretch mt-10">
        <div className="flex w-full max-md:flex-wrap max-md:px-5">
          <div className="flex flex-col justify-center self-start max-md:max-w-full ml-10 w-[50%]">
            <div className="font-bold text-[14px] leading-[143%] text-blue max-md:max-w-full">
              Site Name
            </div>
            <div className="mt-1 text-[14px] leading-[157%] text-zinc-600 max-md:max-w-full">
              Site name is the name your subscribers will know you from.
            </div>
          </div>
          <div className="flex w-[50%]">
            <CustomInput
              title=""
              value={siteName}
              onChange={(e) => setSiteName(e.target.value)}
            />
          </div>
        </div>
        <div className="w-full max-md:flex-wrap max-md:px-5 mt-8 hidden">
          <div className="flex flex-col justify-center self-start max-md:max-w-full ml-10 w-[50%]">
            <div className="font-bold text-[14px] leading-[143%] text-blue max-md:max-w-full">
              Time zone
            </div>
            <div className="mt-1 text-[14px] leading-[157%] text-zinc-600 max-md:max-w-full">
              Your time zone is used for displaying dates and scheduling your
              campaigns.
            </div>
          </div>
          <div className="flex w-[50%]">
            <CustomSelect
              value={siteTimeZone}
              options={timeZoneList?.map((item) => ({
                ...item,
                title: item?.name,
              }))}
              onChange={(e) => setTimeZone(e.target.value)}
            />
          </div>
        </div>
        <div className="hidden w-full max-md:flex-wrap max-md:px-5 mt-8">
          <div className="flex flex-col justify-center self-start max-md:max-w-full ml-10 w-[50%]">
            <div className="font-bold text-[14px] leading-[143%] text-blue max-md:max-w-full">
              Support email address (Optional)
            </div>
            <div className="mt-1 text-[14px] leading-[157%] text-zinc-600 max-md:max-w-full">
              Support email address (Optional) Subscribers can contact you at
              this email address. You can add it as a variable to any message.
              Once added you can modify the email address, but have to ask for
              the support team to delete it. Contact support
            </div>
          </div>
          <div className="flex w-[50%]">
            <CustomInput
              placeholder="Enter"
              value={supportEmail}
              onChange={(e) => setSupportEmail(e.target.value)}
            />
          </div>
        </div>

        <div className="justify-center self-end mr-10 max-md:mr-2.5">
          <CustomButton
            title="Save"
            className="px-10 py-1.5 mt-5"
            onClick={() => updateSiteData()}
          />
        </div>
      </div>

      <div className="hidden mt-5 w-full border border-gray-light border-solid stroke-[1px] stroke-gray-200 max-md:max-w-full" />
      <div className="hidden gap-5 self-stretch px-10 text-sm max-md:flex-wrap max-md:px-5 mt-8">
        <div className="flex flex-col flex-1 justify-center self-start max-md:max-w-full">
          <div className="font-bold leading-[143%] text-blue max-md:max-w-full">
            Currency Change
          </div>
          <div className="mt-1 leading-[157%] text-zinc-600 max-md:max-w-full">
            If you wish to change the default currency for your site, please
            contact our customer support team.
          </div>
        </div>
        <CustomLightButton title="Contact Support" />
      </div>
    </div>
  );
};
export default SiteInformation;
