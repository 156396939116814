/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getCampaignSegmentsListsDetail } from "../../apis/SegmentsLists";
import NotCampaign from "../../assets/icons/NotCampaign";
import CustomLoader from "../../components/CustomLoader";
import RenderTable from "../../components/RenderTable";
import queryParamConstant from "../../utils/queryParamConstant";
import { campaignColumns } from "./DatatableConfig";

const CampaignFlows = ({
  searchFilter,
  sortFilterData,
  startDateRange,
  endDateRange,
  filterSelection,
}) => {
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [isFetchingSearch, setFetchingSearch] = useState(false);
  const [allDataList, setAllDataList] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const { error, isLoading, refetch, isFetching } = useQuery(
    queryParamConstant.GET_SEGMENT_CAMPAIGNS_URL,
    () =>
      getCampaignSegmentsListsDetail({
        segment_id: id,
        filter: {
          search_text: searchFilter,
          popup_type: "",
          status: sortFilterData,
          device: "",
          data_range: filterSelection,
          start_date:
            filterSelection === "custom"
              ? moment(startDateRange).format("YYYY-MM-DD")
              : "",
          end_date:
            filterSelection === "custom"
              ? moment(endDateRange).format("YYYY-MM-DD")
              : "",
        },
        page: page,
        limit: perPage,
        is_with_paginate: 1,
      }),
    {
      refetchOnWindowFocus: false,
      onSuccess: (list) => {
        setFilterData(list?.list);
        setAllDataList(
          page === 1 ? [...list?.list] : [...allDataList, ...list?.list]
        );
      },
    }
  );

  useEffect(() => {
    if (startDateRange && endDateRange) {
      setTimeout(() => refetch(), 10);
    }
  }, [startDateRange, endDateRange]);

  const refetchAll = async () => {
    setFetchingSearch(true);
    const response = await getCampaignSegmentsListsDetail({
      segment_id: id,
      filter: {
        search_text: searchFilter,
        popup_type: "",
        status: sortFilterData,
        device: "",
        data_range: filterSelection,
        start_date:
          filterSelection === "custom"
            ? moment(startDateRange).format("YYYY-MM-DD")
            : "",
        end_date:
          filterSelection === "custom"
            ? moment(endDateRange).format("YYYY-MM-DD")
            : "",
      },
      page: page,
      limit: perPage,
      is_with_paginate: 1,
    });
    setTotal(response?.total);
    setFilterData(response?.list);
    setAllDataList([...allDataList, ...response?.list]);
    setFetchingSearch(false);
  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      refetchAll();
      // Send Axios request here
    }, 800);

    return () => clearTimeout(delayDebounceFn);
  }, [searchFilter]);

  useEffect(() => {
    if (sortFilterData || filterSelection) {
      refetch();
    }
  }, [sortFilterData, filterSelection]);

  if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <div className="mt-10">
      {isLoading ? (
        <div className="h-[500px]">
          <CustomLoader />
        </div>
      ) : filterData ? (
        <RenderTable
          isLoading={isFetching || isFetchingSearch}
          searchData={searchFilter}
          isFilterLayout={false}
          refetch={refetch}
          totalRows={total}
          page={page}
          perPage={perPage}
          setPerPage={setPerPage}
          setPage={setPage}
          setAllDataList={setAllDataList}
          allDataList={allDataList}
          tableData={filterData}
          columns={campaignColumns}
          isSearch={false}
          isFilter={false}
          // onRowClicked={(row) =>
          //   navigate(
          //     "/sequences/" +
          //       row?.category_name?.toLowerCase()?.replace(" ", "-"),
          //     {
          //       state: {
          //         id: row?.campaign_flow_id,
          //         description: row?.description,
          //       },
          //     }
          //   )
          // }
        />
      ) : (
        <div className="flex justify-center items-center flex-col">
          <NotCampaign />
          <div className="flex flex-col">
            <div className="self-center text-[20px] font-medium leading-[22px] text-neutral-700">
              No campaigns sent
            </div>
            <div className="mt-2.5 w-full text-[14px] text-stone-500 font-normal leading-[16.41px]">
              Send campaign targeting this specific segment
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default CampaignFlows;
