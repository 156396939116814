import { useState } from "react";
import CustomStepper from "../../../components/CustomStepper";
import CompanyInformation from "./CompanyInformation";
import ContactInformation from "./ContactInformation";
import FAQ from "./FAQ";
import PasswordChange from "./PasswordChange";
import SiteInformation from "./SiteInformation";

const GeneralTab = ({ data }) => {
  const [activeStepper, setActiveStepper] = useState(0);
  return (
    <div className="flex gap-5 self-center mt-12 max-md:flex-wrap max-md:mt-10 pr-12">
      <div className="w-[20%]">
        <CustomStepper
          active={activeStepper}
          onClick={(index) => setActiveStepper(index)}
        />
      </div>
      <div className="flex flex-col w-[80%] py-5 text-sm bg-white rounded-xl border border-[#DADADA] border-solid max-md:max-w-full ">
        {activeStepper === 0 ? (
          <SiteInformation data={data?.settings?.[0]} />
        ) : activeStepper === 1 ? (
          <ContactInformation data={data?.settings?.[0]} />
        ) : activeStepper === 2 ? (
          <CompanyInformation data={data?.settings?.[0]} />
        ) : activeStepper === 3 ? (
          <PasswordChange data={data?.settings?.[0]} />
        ) : (
          <FAQ />
        )}
      </div>
    </div>
  );
};
export default GeneralTab;
