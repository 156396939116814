/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from "react-query";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCampaignFlowsList } from "../../apis/CampaignFlows";
import CustomLoader from "../../components/CustomLoader";
import RenderTable from "../../components/RenderTable";
import queryParamConstant from "../../utils/queryParamConstant";
import columns from "./DatatableConfig";
import moment from "moment";

const CampaignFlowsPage = () => {
  const navigate = useNavigate();
  const [selectedRange, setSelectedRange] = useState("all");
  const [page, setPage] = useState(1);
  const [allDataList, setAllDataList] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [startDateRange, setStartDateRange] = useState();
  const [endDateRange, setEndDateRange] = useState();
  const { data, error, isLoading, isFetching, refetch } = useQuery(
    queryParamConstant.GET_CAMPAIGN_FLOWS_OVERVIEW_URL,
    () =>
      getCampaignFlowsList({
        filter: {
          search_text: "",
          popup_type: "",
          status: "",
          device: "",
          data_range: selectedRange,
          start_date:
            selectedRange === "custom"
              ? moment(startDateRange).format("YYYY-MM-DD")
              : "",
          end_date:
            selectedRange === "custom"
              ? moment(endDateRange).format("YYYY-MM-DD")
              : "",
        },
        page: page,
        limit: perPage,
        is_with_paginate: 1,
      }),
    {
      refetchOnWindowFocus: false,
      onSuccess: (list) => {
        setFilterData(list?.list);
        setAllDataList(
          page === 1 ? [...list?.list] : [...allDataList, ...list?.list]
        );
      },
    }
  );

  useEffect(() => {
    if (startDateRange && endDateRange) {
      setAllDataList([]);
      setTimeout(() => refetch(), 10);
    }
  }, [startDateRange, endDateRange]);
  if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <div className="relative mb-10 bg-white rounded-[10px] border-2 border-[#DADADA] py-6">
      {isLoading ? (
        <div className="h-[500px]">
          <CustomLoader />
        </div>
      ) : (
        <RenderTable
          isLoading={isFetching}
          refetch={refetch}
          totalRows={data?.total}
          page={page}
          perPage={perPage}
          setPerPage={setPerPage}
          setPage={setPage}
          allDataList={allDataList}
          tableData={filterData}
          columns={columns}
          isSearch={false}
          isLeftSideFilter={false}
          isFilter={false}
          startDateRange={startDateRange}
          endDateRange={endDateRange}
          setStartDateRange={setStartDateRange}
          setEndDateRange={setEndDateRange}
          filterSelection={selectedRange}
          onFilterChange={(item) => {
            if (item !== "custom") {
              setPage(1);
              setSelectedRange(item);
              setTimeout(() => refetch(), 10);
            } else {
              setPage(1);
              setSelectedRange(item);
            }
          }}
          onRowClicked={(row) =>
            navigate(
              "/sequences/" +
                row?.category_name?.toLowerCase()?.replace(" ", "-"),
              {
                state: {
                  id: row?.campaign_flow_id,
                  description: row?.description,
                },
              }
            )
          }
        />
      )}
    </div>
  );
};
export default CampaignFlowsPage;
