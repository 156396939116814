import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getSegmentsSubscribersDetail } from "../../apis/SegmentsLists";
import CustomLoader from "../../components/CustomLoader";
import RenderTable from "../../components/RenderTable";
import queryParamConstant from "../../utils/queryParamConstant";
import { membersColumns } from "./DatatableConfig";

const Members = ({ searchFilter }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [selectedRange, setSelectedRange] = useState("all");
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [isFetchingSearch, setFetchingSearch] = useState(false);
  const [allDataList, setAllDataList] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const { error, isLoading, refetch, isFetching } = useQuery(
    queryParamConstant.GET_SEGMENT_SUBSCRIBERS_URL,
    () =>
      getSegmentsSubscribersDetail({
        id: id,
        filter: {
          search_text: searchFilter,
          popup_type: "",
          status: "",
          device: "",
          data_range: selectedRange,
          start_date: "",
          end_date: "",
        },
        page: page,
        limit: perPage,
        is_with_paginate: 1,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: !!id,
      onSuccess: (list) => {
        setFilterData(list?.list);
        setAllDataList(
          page === 1 ? [...list?.list] : [...allDataList, ...list?.list]
        );
      },
    }
  );
  const refetchAll = async () => {
    setFetchingSearch(true);
    const response = await getSegmentsSubscribersDetail({
      id: id,
      filter: {
        search_text: searchFilter,
        popup_type: "",
        status: "",
        device: "",
        data_range: selectedRange,
        start_date: "",
        end_date: "",
      },
      page: page,
      limit: perPage,
      is_with_paginate: 1,
    });
    setTotal(response?.total);
    setFilterData(response?.list);
    setAllDataList([...allDataList, ...response?.list]);
    setFetchingSearch(false);
  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      refetchAll();
      // Send Axios request here
    }, 800);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilter]);

  if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <div className="relative bg-white rounded-[10px] border-t-2 border-[#DADADA] py-4 mt-10">
      {isLoading ? (
        <div className="h-[500px]">
          <CustomLoader />
        </div>
      ) : (
        <RenderTable
          isLoading={isFetching || isFetchingSearch}
          searchData={searchFilter}
          isFilterLayout={false}
          refetch={refetch}
          totalRows={total}
          page={page}
          perPage={perPage}
          setPerPage={setPerPage}
          setPage={setPage}
          allDataList={allDataList}
          tableData={filterData}
          columns={membersColumns}
          filterSelection={selectedRange}
          setAllDataList={setAllDataList}
          onFilterChange={(item) => {
            setPage(1);
            setAllDataList([]);
            setSelectedRange(item);
            setTimeout(() => refetch(), 10);
          }}
          onRowClicked={(row) =>
            navigate("/subscribers/" + row?.subscriber_segment_id, {
              state: {
                id: row?.subscriber_segment_id,
                description: row?.description,
              },
            })
          }
        />
      )}
    </div>
  );
};
export default Members;
