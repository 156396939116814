import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  addRoleRight,
  getRight,
  getTermsTypes,
} from "../../../apis/ManageRoles";
import CustomButton from "../../../components/CustomButton";
import CustomInput from "../../../components/CustomInput";
import CustomSelect from "../../../components/CustomSelect";
import PermissionCheckBoxs from "../../../components/PermissionCheckBoxs";
import queryParamConstant from "../../../utils/queryParamConstant";

const AddRolePage = () => {
  const [isOpenRights, setOpenRights] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedUserType, setSelectedUserType] = useState("");
  const [roleName, setRoleName] = useState("");
  const { data: roleTypeList } = useQuery(
    queryParamConstant.GET_TERM_TYPES_URL_ROLE,
    () => getTermsTypes({ term_category_code: "role_type" }),
    { refetchOnWindowFocus: false }
  );
  const { data: userTypeList } = useQuery(
    queryParamConstant.GET_TERM_TYPES_URL_USER,
    () => getTermsTypes({ term_category_code: "user_type" }),
    { refetchOnWindowFocus: false }
  );

  useMutation(
    queryParamConstant.ADD_ROLE_RIGHTS_URL,
    () =>
      addRoleRight({
        role_id: "roleId",
        rights: [
          {
            uuid: "fcbf137a-224d-4e8b-8c75-fb03df46287f",
            role_is_view: 1,
            role_is_create: 0,
            role_is_update: 1,
            role_is_delete: 1,
            role_is_export: 1,
          },
        ],
      }),
    { refetchOnWindowFocus: false }
  );

  const { data: rightsList } = useQuery(
    queryParamConstant.GET_RIGHTS_URL,
    () =>
      getRight({
        role_term_id: selectedRole,
        user_term_id: selectedUserType,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: !!selectedRole && !!selectedUserType && !!isOpenRights,
    }
  );
  return (
    <div className="relative">
      <div className="flex flex-col pt-14 pb-20 text-sm leading-5 bg-white rounded-xl border-2 border-solid border-gray-light">
        <div className="flex gap-5 justify-center max-md:flex-wrap">
          <CustomSelect
            value={
              roleTypeList?.find((item) => item?.label === selectedRole)?.label
            }
            isDefault={false}
            onChange={(e) =>
              setSelectedRole(
                roleTypeList?.find((item) => item?.label === e.target.value)
                  ?.value
              )
            }
            title="Role Type"
            options={roleTypeList?.map((item) => ({
              title: item?.label,
              id: item?.value,
            }))}
          />
          <CustomSelect
            value={
              userTypeList?.find((item) => item?.label === selectedUserType)
                ?.label
            }
            isDefault={false}
            onChange={(e) =>
              setSelectedUserType(
                userTypeList?.find((item) => item?.label === e.target.value)
                  ?.value
              )
            }
            title="User Type"
            options={userTypeList?.map((item) => ({
              title: item?.label,
              id: item?.value,
            }))}
          />
        </div>
        <div className="flex flex-col mt-8 max-w-full w-[675px] max-md:px-5">
          <CustomInput
            title="Role Name"
            placeholder="Enter"
            type="text"
            value={roleName}
            onChange={(e) => setRoleName(e.target.value)}
          />
        </div>
        {!isOpenRights && (
          <CustomButton
            disabled={
              selectedUserType === undefined ||
              selectedRole === undefined ||
              selectedUserType === "" ||
              selectedRole === ""
            }
            onClick={() => setOpenRights(!isOpenRights)}
            title="Show Rights"
            className={"mr-10 mt-8 px-5 py-1.5"}
          />
        )}
        {isOpenRights && (
          <>
            <div className="mt-8 w-full border border-gray-light border-solid stroke-[1px] stroke-gray-200 max-md:max-w-full" />
            <div className="md:mx-20  xl:px-10">
              <div className="flex flex-wrap justify-around gap-10 my-16 w-full">
                {rightsList?.map((item) => (
                  <div className="flex flex-col items-start p-5 rounded border-2 border-gray-light border-solid max-md:mt-10 max-md:max-w-full self-start">
                    <PermissionCheckBoxs
                      title={item?.form_detail}
                      item={item}
                      roleNewList={rightsList}
                      // setRoleNewList={setRoleNewList}
                    />
                  </div>
                ))}
                <div className="flex flex-col items-start p-5 w-[500px] rounded max-md:mt-10 max-md:max-w-full self-start">
                  <CustomButton
                    onClick={() => setOpenRights(!isOpenRights)}
                    title="Add"
                    className={"px-5 py-1.5"}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default AddRolePage;
