import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from "react-router-dom";
import BasicLayout from "../layout/BasicLayout";
import AutomatedFlowsPage from "../pages/AutomatedFlowsPage";
import BillingPage from "../pages/BillingPage";
import CampaignFlowsPage from "../pages/CampaignFlowsPage";
import HomePage from "../pages/HomePage";
import InboxPage from "../pages/InboxPage";
import ManageRolesPage from "../pages/ManageRolesPage";
import AddRolePage from "../pages/ManageRolesPage/AddRolePage";
import OptInToolsPage from "../pages/OptInToolsPage";
import ReportsPage from "../pages/ReportsPage";
import SegmentsListsPage from "../pages/SegmentsListsPage";
import SubscribersPage from "../pages/SubscribersPage";
import CampaignFlowsDetailsPage from "../pages/CampaignFlowsDetailsPage";
import SettingsPage from "../pages/SettingsPage/SettingsPage";
import AutomatedFlowsDetailPage from "../pages/AutomatedFlowsDetailPage";
import SegmentsDetailsPage from "../pages/SegmentsDetailsPage/SegmentsDetailsPage";
import SubscribersDetailPage from "../pages/SubscribersDetailPage";
import LoginPage from "../pages/LoginPage";
import ForgotPasswordPage from "../pages/ForgotPasswordPage";
import ResetPasswordPage from "../pages/ResetPasswordPage";
import history from "./history";

const CustomRoutes = () => {
  const PrivateRoute = ({ children }) => {
    const tokenLatest = localStorage.getItem("sms-token");
    return tokenLatest ? children : <Navigate to="/login" />;
  };
  return (
    <Router history={history}>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        {/* <Route
          path="/reset/password/:emailToken"
          element={<ResetPasswordPage />}
        /> */}
        <Route
          path="/"
          element={
            <PrivateRoute>
              <BasicLayout />
            </PrivateRoute>
          }
        >
          <Route index element={<HomePage />} />
          <Route index element={<HomePage />} path="dashboard" />
          <Route index element={<OptInToolsPage />} path="optin-tools" />
          <Route index element={<ReportsPage />} path="reports" />{" "}
          <Route
            index
            element={<SubscribersPage index={0} />}
            path="subscribers"
          />
          <Route
            index
            element={<SubscribersPage index={0} />}
            path="subscribers/overview"
          />
          <Route
            index
            element={<SubscribersPage index={1} />}
            path="subscribers/profiles"
          />
          <Route
            index
            element={<SubscribersPage index={2} />}
            path="subscribers/import-subscribers"
          />
          <Route
            index
            element={<SubscribersPage index={3} />}
            path="subscribers/audience"
          />
          <Route
            index
            element={<SubscribersDetailPage />}
            path="subscribers/:id"
          />
          <Route index element={<SettingsPage />} path="settings/general" />
          <Route index element={<SettingsPage isSMS />} path="settings/sms" />
          <Route index element={<SegmentsListsPage />} path="segments" />
          <Route index element={<SegmentsDetailsPage />} path="segments/:id" />
          <Route index element={<InboxPage />} path="inbox" />
          <Route index element={<CampaignFlowsPage />} path="campaign-flows" />
          <Route
            index
            element={<CampaignFlowsDetailsPage />}
            path="sequences/:id"
          />
          <Route index element={<BillingPage />} path="billing" />
          <Route
            index
            element={<AutomatedFlowsPage />}
            path="automated-flows"
          />
          <Route
            index
            element={<AutomatedFlowsDetailPage />}
            path="automated-flows/:id"
          />
          <Route index element={<ManageRolesPage />} path="roles" />
          <Route index element={<AddRolePage />} path="roles/add" />
        </Route>
        <Route
          path="/*"
          element={
            <PrivateRoute>
              <Navigate to="/dashboard" />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default CustomRoutes;
