import axios from "axios";
import { toast } from "react-toastify";

const API_ENDPOINT =
  process.env.REACT_APP_API_BACKEND_URL || "https://api.teami.simplesms.com/";

const dataService = axios.create({
  baseURL: API_ENDPOINT,
});

dataService.interceptors.request.use(
  (config) => {
    config.headers.Authorization = localStorage.getItem("sms-token");
    return config;
  },
  (error) => {
    return error;
  }
);

dataService.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // localStorage.clear();
    // window.location.reload();
    toast.error(error.response.data.message, {
      toastId: "1",
    });
    return Promise.reject(error);
  }
);

export { dataService };
//  dataService
//    .post(Api.Login, data)
//    .then(({ data }) => {
//      localStorage.setItem("token", data.data);
//      navigate("/admin/category-list");
//    })
//    .catch((e) => {
//      toast.error("invalid credentials", {
//        toastId: "1",
//      });
//    });
