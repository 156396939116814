/* eslint-disable react-hooks/exhaustive-deps */
import ReactECharts from "echarts-for-react";
import React, { useEffect, useState } from "react";
import Flatpickr from "react-flatpickr";
import {
  subscriberChartRange,
  subscribersTypeFilter,
} from "../../global/config";
import { formatDate } from "../../utils/formatDate";
import CustomDropdowns from "../CustomDropdowns";
import CustomLoader from "../CustomLoader";
import Config from "./config";

const LineChart = ({
  isLoading,
  lineData,
  onFilterChange,
  filterSelection,
  onFilterDayChange,
  filterSelectionDay,
  setDropdownSelection,
  dropdownSelection,
  isDropdownSubscriptions = false,
  isMonthFilter = true,
  setDateRange,
  setEndDateRange,
  setStartDateRange,
  setDateRangeValue,
  dateRangeValue,
  dateRange,
  lastMonth,
}) => {
  const [data, setData] = useState(Config);
  const handleSelectRange = (data) => {
    if (data === "custom") {
      setDateRange(data);
    } else {
      onFilterChange(data);
    }
  };
  useEffect(() => {
    if (lineData?.data?.length > 0) {
      setData({
        ...data,
        series: [
          {
            type: "line",
            width: "4px",
            color: "#2B61AB",
            smooth: true,
            showSymbol: false,
            data: lineData?.data.map((item) => item.counts),
          },
          lastMonth && {
            type: "line",
            width: "4px",
            color: "#D6E4F5",
            smooth: true,
            showSymbol: false,
            data: lastMonth?.data.map((item) => item.counts),
          },
        ],
        yAxis: {
          ...data.yAxis,
          min:
            dropdownSelection === "subscribers_count"
              ? Math.min(...lineData?.data.map((item) => item.counts))
              : 0,
          max: lineData?.max,
        },
        xAxis: {
          ...data.xAxis,
          data: lineData?.data.map((item) => formatDate(item.date)),
          splitLine: { show: false },
          axisLine: { show: false },
          axisTick: { show: false },
        },
      });
    }
  }, [lineData, lastMonth]);
  return (
    <div className="flex flex-col justify-center px-12 pt-6 pb-12 bg-white rounded-xl border border-solid shadow-lg border-gray-light max-md:px-5">
      <div className="flex gap-5 justify-between px-0.5 w-full max-md:flex-wrap max-md:max-w-full">
        <div className="text-lg gap-5 font-bold leading-7 text-blue">
          <span>
            {lineData?.data?.reduce((sum, cur) => sum + cur.counts, 0)}
          </span>
          {isDropdownSubscriptions ? (
            <span className="ml-5">
              <CustomDropdowns
                optionsList={subscribersTypeFilter}
                onChange={setDropdownSelection}
                selected={dropdownSelection}
                isArrow={true}
                isBgblue={false}
              />
            </span>
          ) : (
            <span> New Subscriptions</span>
          )}
        </div>
        <div className="flex">
          {isMonthFilter && (
            <CustomDropdowns
              onChange={onFilterDayChange}
              selected={filterSelectionDay}
              isBgblue={false}
              isArrow={true}
              optionsList={subscriberChartRange}
            />
          )}
          <div className="mx-4">
            {!dateRange ? (
              <CustomDropdowns
                isBgblue={false}
                isCalender
                onChange={(item) => handleSelectRange(item)}
                selected={filterSelection}
              />
            ) : (
              <Flatpickr
                className="flex h-[47px] px-5 py-2.5 rounded border border-gray-light border-solid"
                value={dateRangeValue}
                placeholder="Select Custom Range"
                options={{ mode: "range" }}
                onChange={(date) => {
                  setDateRangeValue(date);
                  setStartDateRange(date[0]);
                  if (date.length > 1) {
                    setDateRange();
                    onFilterChange(dateRange);
                    setEndDateRange(date[1]);
                  }
                }}
              />
            )}
          </div>
        </div>
      </div>
      {isLoading ? (
        <div className="h-[280px]">
          <CustomLoader />
        </div>
      ) : (
        <ReactECharts option={data} notMerge={true} lazyUpdate={true} />
      )}
    </div>
  );
};
export default LineChart;
