import { useState } from "react";
import { Outlet } from "react-router-dom";

import CustomHeader from "../components/CustomHeader";
import Sidebar from "../components/Sidebar";

const BasicLayout = () => {
  const [isOpenSidebar, setOpenSidebar] = useState(false);

  return (
    <>
      <CustomHeader
        setOpenSidebar={setOpenSidebar}
        isOpenSidebar={isOpenSidebar}
      />
      <Sidebar setOpenSidebar={setOpenSidebar} isOpenSidebar={isOpenSidebar} />

      <div className={`basic-header ${isOpenSidebar ? "open-sidebar" : ""} `}>
        <div className="ml-5 mr-6 -mt-16 z-20">
          <Outlet />
        </div>
      </div>
    </>
  );
};
export default BasicLayout;
