import { useEffect, useRef, useState } from "react";
import Line from "../../assets/svg/line";
import { rangeFilter } from "../../global/config";
import CalenderIcon from "../../assets/icons/CalenderIcon";
import DownArrowIcon from "../../assets/icons/DownArrowIcon";

const CustomDropdowns = ({
  selected = "All Time",
  onChange,
  isBgblue = true,
  isCalender = false,
  isArrow = false,
  optionsList = rangeFilter,
  className,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(selected);
  const handleSelected = (item) => {
    setOpen(false);
    setSelectedValue(item);
    onChange(item);
  };
  const btnRef = useRef();

  useEffect(() => {
    const closeDropdown = (e) => {
      if (!btnRef.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.body.addEventListener("click", closeDropdown);
    return () => document.body.removeEventListener("click", closeDropdown);
  }, []);

  return (
    <div className="relative inline-block text-left" ref={btnRef}>
      <div>
        <button
          type="button"
          className={`flex justify-center text-[14px] items-center whitespace-nowrap rounded leading-[157%] max-md:mr-2.5 self-end px-3 ${
            isBgblue
              ? "bg-blue-800 text-white h-[48px] font-medium"
              : "text-blue-800 border-border-gray border-solid border-2 h-[34px] font-normal "
          } ${className}`}
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
          onClick={() => setOpen(!isOpen)}
        >
          {optionsList[selectedValue]}
          <span className="ml-3">
            {isArrow ? (
              <DownArrowIcon />
            ) : isCalender ? (
              <CalenderIcon />
            ) : (
              <Line />
            )}
          </span>
        </button>
      </div>

      {isOpen && (
        <div
          className="absolute right-0 text-[14px] z-10 mt-2 min-w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabindex="-1"
        >
          <div
            className=" flex flex-col text-left justify-start items-start px-5 pt-5"
            role="none"
          >
            {Object.keys(optionsList).map((item) => (
              <button
                className={`mb-5 text-left ${
                  selectedValue === item
                    ? "font-semibol text-blue-800"
                    : "font-normal text-blue"
                } `}
                onClick={() => handleSelected(item)}
              >
                {optionsList[item]}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
export default CustomDropdowns;
