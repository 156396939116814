import SegmentsIcon from "../../assets/icons/SegmentsIcon";
import CustomTitle from "../../components/RenderTableComponents/CustomTitle";
import NormalText from "../../components/RenderTableComponents/NormalText";
import { formatDate } from "../../utils/formatDate";

const columns = [
  {
    name: "Name",
    selector: (row) => row.name,
    sortable: true,
    width: "80%",
    cell: (row) => (
      <CustomTitle
        title={row?.name}
        isDescription={false}
        description={
          <div className="flex items-center">
            <SegmentsIcon />
            <div className="ml-2">{row?.type}</div>
          </div>
        }
        id={row?.id}
        link={"/segments/" + row?.id}
      />
    ),
  },
  {
    name: "Members",
    selector: (row) => row.members,
    sortable: true,
    cell: (row) => <NormalText row={row?.members} />,
  },
  {
    name: "Last Edited",
    selector: (row) => row.updated_at,
    sortable: true,
    cell: (row) => <NormalText row={formatDate(row?.updated_at)} />,
  },
];
export default columns;
