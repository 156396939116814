import { useState } from "react";
import CustomStepper from "../../../components/CustomStepper";
import ContactCard from "./ContactCard";
import SendingNumber from "./SendingNumber";

const SMSTab = ({ data }) => {
  const [activeStepper, setActiveStepper] = useState(0);
  return (
    <div className="flex gap-5 self-center mt-12 max-md:flex-wrap max-md:mt-10 pr-12">
      <div className="w-[20%]">
        <CustomStepper
          active={activeStepper}
          onClick={(index) => setActiveStepper(index)}
          stepperList={[
            { id: 1, title: "Sending Number" },
            { id: 3, title: "Contact card" },
          ]}
        />
      </div>
      <div className="flex flex-col w-[80%] py-5 text-sm bg-white rounded-xl border border-[#DADADA] border-solid max-md:max-w-full ">
        {activeStepper === 0 ? (
          <SendingNumber data={data?.sending_numbers} />
        ) : activeStepper === 1 ? (
          <ContactCard data={data?.settings?.[0]} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default SMSTab;
