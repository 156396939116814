import BrowseAbdIcon from "../../assets/icons/BrowseAbdIcon";
import CartAbdIcon from "../../assets/icons/CartAbdIcon";
import CustomTriggersIcon from "../../assets/icons/CustomTriggersIcon";
import FulfillNotificationIcon from "../../assets/icons/FulfillNotificationIcon";
import HelpIcon from "../../assets/icons/HelpIcon";
import HiIcon from "../../assets/icons/HiIcon";
import OrderReceiptIcon from "../../assets/icons/OrderReceiptIcon";
import ReceiptIcon from "../../assets/icons/ReceiptIcon";
import SMSAbandonmentIcon from "../../assets/icons/SMSAbandonmentIcon";
import SMSCampaignIcon from "../../assets/icons/SMSCampaignIcon";
import CustomTitle from "../../components/RenderTableComponents/CustomTitle";
import NormalText from "../../components/RenderTableComponents/NormalText";
import { autoFlowsLink } from "../../global/config";
import formatNumber from "../../utils/formatNumber";

const icon = (heading) => {
  let icon = <HiIcon />;
  switch (heading) {
    case "Welcome":
      icon = <HiIcon />;
      break;
    case "Custom Triggers":
      icon = <CustomTriggersIcon />;
      break;
    case "Browse Abandonment":
      icon = <BrowseAbdIcon />;
      break;
    case "Abandoned Cart":
      icon = <CartAbdIcon />;
      break;
    case "Receipt":
      icon = <ReceiptIcon />;
      break;
    case "Fulfillment Notification":
      icon = <FulfillNotificationIcon />;
      break;
    case "Help":
      icon = <HelpIcon />;
      break;
    case "SMS Campaigns":
      icon = <SMSCampaignIcon />;
      break;
    case "SMS Abandonment":
      icon = <SMSAbandonmentIcon />;
      break;
    case "Order & Receipt":
      icon = <OrderReceiptIcon />;
      break;
    default:
      icon = <HiIcon />;
      break;
  }
  return icon;
};
const columns = [
  {
    name: "Image",
    sortable: false,
    width: "5.2%",
    cell: (row) => (
      <div className="flex justify-center items-center ml-2">
        {icon(row.category_name)}
      </div>
    ),
  },
  {
    name: "Category",
    selector: (row) => row.category_name,
    sortable: true,
    width: "40%",
    cell: (row) => (
      <CustomTitle
        title={row?.category_name}
        description={row?.description}
        link={
          autoFlowsLink?.[
            row?.category_name
              ?.toLowerCase()
              ?.replaceAll(" ", "_")
              ?.replace("&", "")
          ]
        }
        id={row?.automated_flow_id}
      />
    ),
  },
  {
    name: "Messages Sent",
    selector: (row) => row.messages_sent,
    sortable: true,
    cell: (row) => <NormalText row={formatNumber(row.messages_sent)} />,
  },
  {
    name: "Spent",
    selector: (row) => row.spent,
    sortable: true,
    cell: (row) => <NormalText row={`$${formatNumber(row.spent)}`} />,
  },
  {
    name: "Sales",
    selector: (row) => row.sales,
    sortable: true,
    cell: (row) => <NormalText row={`$${formatNumber(row.sales)}`} />,
  },
  {
    name: "RPM",
    selector: (row) => row.rpm,
    sortable: true,
    cell: (row) => <NormalText row={`$${formatNumber(row.rpm)}`} />,
  },
  {
    name: "ROI",
    selector: (row) => row.roi,
    sortable: true,
    cell: (row) => <NormalText row={`${formatNumber(row.roi)}X`} />,
  },
];
export default columns;
