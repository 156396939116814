import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Pagination from "./Pagination";
import RenderFilter from "./RenderFilter";
import Skeleton from "react-loading-skeleton";

const RenderTable = ({
  allDataList,
  tableData,
  columns,
  isSearch,
  isFilter,
  isDateFilter,
  isLeftSideFilter,
  onRowClicked,
  customButtonRight,
  customButtonLeft,
  isFilterLayout = true,
  filterSelection,
  onFilterChange,
  refetch,
  totalRows,
  page,
  setPage,
  perPage,
  setPerPage,
  leftSideFilter,
  searchFilterData,
  onSearchFilter,
  startDateRange,
  endDateRange,
  setStartDateRange,
  setEndDateRange,
  searchData,
  setAllDataList,
  isLoading = false,
}) => {
  const [filterData, setFilterData] = useState([]);
  const previousPage = (temp) => {
    const s = (temp - 1) * perPage;
    const e = s + perPage;
    setFilterData([...allDataList.slice(s, e)]);
  };
  const nextPage = () => {
    if (allDataList.length <= page * perPage) {
      setTimeout(() => refetch(), 100);
    } else {
      const s = (page + 1) * perPage;
      const e = s + perPage;
      setFilterData([...allDataList.slice(s, e)]);
    }
  };
  useEffect(() => {
    if (tableData) {
      setFilterData([]);
    }
  }, [tableData]);

  return (
    <div>
      {isFilterLayout && (
        <RenderFilter
          customButtonRight={customButtonRight}
          customButtonLeft={customButtonLeft}
          isLeftSideFilter={isLeftSideFilter}
          isFilter={isFilter}
          isSearch={isSearch}
          isDateFilter={isDateFilter}
          filterSelection={filterSelection}
          onFilterChange={onFilterChange}
          leftSideFilter={leftSideFilter}
          onSearchFilter={onSearchFilter}
          startDateRange={startDateRange}
          endDateRange={endDateRange}
          setStartDateRange={setStartDateRange}
          setEndDateRange={setEndDateRange}
          searchFilter={searchData}
        />
      )}
      {isLoading ? (
        <div className="mt-5">
          <Skeleton count={10} className="my-1 py-3" />
        </div>
      ) : tableData?.length > 0 ? (
        <div className={isFilterLayout && "pt-8"}>
          <DataTable
            columns={columns}
            data={filterData.length > 0 ? filterData : tableData}
            onRowClicked={onRowClicked}
          />
        </div>
      ) : (
        <div className="text-[14px] text-zinc-600 font-medium text-center my-10">
          No data found!
        </div>
      )}
      {totalRows > 10 && (
        <Pagination
          perPage={perPage}
          page={page}
          previousPage={previousPage}
          refetch={refetch}
          totalRows={totalRows}
          setPerPage={(item) => {
            setAllDataList([]);
            setPage(1);
            setPerPage(item);
            setTimeout(() => refetch(), 100);
          }}
          setPage={setPage}
          nextPage={nextPage}
        />
      )}
    </div>
  );
};
export default RenderTable;
